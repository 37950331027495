<template>
    <Overview 
        :moduleName="$tc('navigation.' + this.$route.name, 1)"
        :moduleNamePlural="$tc('navigation.' + this.$route.name, 2)"
        :sort="'number'"
        :sortOrder="-1"
        :itemService=itemService
        :itemFormRef=itemFormRef
        ref="overview"
    >
        <template #columns>
            <Column field="debtor.customer.name" :header="$t('navigation.customers')" :sortable="true"></Column>
            <Column field="number" :header="$t('overview.number')" :sortable="true" style="width: 10%"></Column>
            <Column field="name" :header="$t('overview.name')" :sortable="true"></Column>
            <Column field="representative" :header="$t('company.representative')" :sortable="true" style="width: 20%"></Column>
            <Column field="company_director" :header="$t('company.company_director')" :sortable="true" style="width: 20%"></Column>
        </template>

        <template #form>
            <CompanyForm ref="ItemForm" :itemData="item" />
        </template>

        <template #modal-footer>
            <!--<button id="nextbutton" type="button" class="btn btn-success" @click.prevent="next">&gt;&ensp;Volgende<span id="nextStep">2</span></button>-->
        </template>
    </Overview>
  <!-- <div class="footer-buttons mb-2">
    <button type="button" class="btn action-button" data-bs-toggle="modal" data-bs-target="#formModal" @click="$refs.overview.addItem()">{{ $t('form.add', [$tc('navigation.' + this.$route.name, 1)]) }}</button>
  </div> -->
  <button id="inv_button" type="button" class="d-none" @click="index"></button>
</template>

<script>
import store from '@/store/user'
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import companyService from '@/services/CompanyService';
import CompanyForm from '@/components/forms/CompanyForm.vue';

export default {
    name: 'Companies',
    components: {
        Overview,
        Column,
        CompanyForm
    },
    data() {
        return {
            item: {},
            itemFormRef: null,
            itemService: companyService,
        }
    },
    methods: {
        index(){            
            this.$refs.overview.getIndexEmployees();
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
        if(!store.getters.getIsHR){
            this.$router.push({
                name: "Home"
            });
        }
    }
}
</script>